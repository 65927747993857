import * as React from "react";
import { Component } from "react";
import { Page, Heading, Button, Link } from "../components";
import { graphql, StaticQuery } from "gatsby";
const slugify = require("slugify");
import * as downsize from "downsize";
import { format } from "date-fns";

import "./News.css";

interface RichTextContent {
  data: object;
  marks: object[];
  value: string;
  nodeType: string;
  content: RichTextContent[];
}

interface NewsPageProps {
  data: {
    contentfulSitePage: {
      title: string;
      body: {
        childContentfulRichText: {
          html: string;
        };
        json: {
          content: RichTextContent[];
        };
      };
    };
  };
}

class NewsPage extends Component<NewsPageProps> {
  public render(): JSX.Element {
    return (
      <StaticQuery
        query={graphql`
          query AllContentfulStaffMember2 {
            allContentfulNewsArticle(sort: { fields: createdAt, order: DESC }) {
              edges {
                node {
                  title
                  createdAt
                  contentful_id
                  childContentfulNewsArticleContentRichTextNode {
                    childContentfulRichText {
                      html
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => {
          const urlParams = new URLSearchParams(this.props.location.search);

          let articleLists = data.allContentfulNewsArticle
            ? data.allContentfulNewsArticle.edges
            : [];

          if (articleLists.length && urlParams.has("title")) {
            articleLists = articleLists.filter(article => {
              return (
                slugify(article.node.title.toLowerCase()) ===
                urlParams.get("title")
              );
            });
          }

          return (
            <Page title="News" layout="default">
              <div className="flex-wrapper section-light-gradient">
                <div className="section-white-gradient" />

                <div className="tile--two-thirds">
                  {urlParams.has("title") && (
                    <Link
                      href="/news"
                      className="news-back-link link-with-icon"
                    >
                      ← Back to all news
                    </Link>
                  )}
                  {articleLists.map((article, articleIndex) => {
                    const {
                      title,
                      createdAt,
                      childContentfulNewsArticleContentRichTextNode
                    } = article.node;
                    const formattedDate = format(createdAt, "MMMM DD, YYYY");
                    const formattedHtml = urlParams.has("title")
                      ? childContentfulNewsArticleContentRichTextNode
                          .childContentfulRichText.html
                      : downsize(
                          childContentfulNewsArticleContentRichTextNode
                            .childContentfulRichText.html,
                          { characters: 240, append: "..." }
                        );
                    return (
                      <div
                        className="tile news-tile"
                        key={`news-article-${articleIndex}`}
                      >
                        <Heading level={3}>{title}</Heading>
                        <p>{formattedDate}</p>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: formattedHtml
                          }}
                        />
                        {!urlParams.has("title") && (
                          <Button
                            href={`/news/?title=${slugify(
                              title
                            ).toLowerCase()}`}
                          >
                            Read More
                          </Button>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </Page>
          );
        }}
      />
    );
  }
}

export default NewsPage;
